/* We need to trigger a little earlier for the events list page due to the width of the layout */
/* We need to trigger a little earlier for the events list page due to the width of the layout */
.main-content-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.no-padding {
  padding: 0; }

.text-center {
  text-align: center; }

.toast-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: -1;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .toast-wrapper.active {
    height: 4rem;
    opacity: 1;
    z-index: 99999999; }
  .toast-wrapper .toast-message {
    color: white;
    padding: 0 0.125rem 0 1rem;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .toast-wrapper .toast-message .toast-message-text-wrapper {
      display: flex;
      align-items: center; }
      .toast-wrapper .toast-message .toast-message-text-wrapper .toast-message-text {
        flex: 1; }
      .toast-wrapper .toast-message .toast-message-text-wrapper .close-button {
        width: 3rem;
        text-align: center;
        color: white; }
        .toast-wrapper .toast-message .toast-message-text-wrapper .close-button:hover {
          color: rgba(255, 255, 255, 0.75); }

.button-custom-primary {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out,color 0.25s ease-out;
  font-size: 1.125rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1a4c9a;
  color: #e3e3e3;
  border-radius: 0.25rem;
  outline: none; }
  .button-custom-primary:hover {
    background-color: #164184;
    color: white; }

.button-custom-secondary {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out,color 0.25s ease-out;
  font-size: 1.125rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #4cbdf9;
  color: #e3e3e3;
  border-radius: 0.25rem;
  outline: none; }
  .button-custom-secondary:hover {
    background-color: #33b4f8;
    color: white; }

.button-custom-highlight {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out,color 0.25s ease-out;
  font-size: 1.125rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #78b270;
  color: #e3e3e3;
  border-radius: 0.25rem;
  outline: none; }
  .button-custom-highlight:hover {
    background-color: #68a95f;
    color: white; }

.loader,
.loader:before,
.loader:after {
  background: #1a4c9a;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em; }

.loader {
  color: #1a4c9a;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: ''; }

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.loader:after {
  left: 1.5em; }

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

.registration-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-bottom: 6rem;
  height: 100vh; }
  .registration-form-wrapper .button {
    border-radius: 0.25rem;
    font-weight: bold; }
  .registration-form-wrapper .input-group .input-group-label {
    background-color: white;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    padding: 0.75rem; }
    .registration-form-wrapper .input-group .input-group-label i {
      font-size: 1.25rem;
      color: #7b858e; }
  .registration-form-wrapper .input-group input {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 1.5rem 0.5rem; }

.event-banner {
  height: 27vw;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0; }

.events-list-section {
  background: white;
  min-height: 100vh; }
  .events-list-section .logo-wrapper {
    margin-bottom: 1rem;
    text-align: center; }
    .events-list-section .logo-wrapper img {
      max-width: 360px; }
  .events-list-section #logo-subheader {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2.5rem; }
  .events-list-section h1 {
    display: inline;
    text-align: center; }
  .events-list-section .loginBtn {
    display: inline;
    margin-top: 12px;
    float: right; }
  .events-list-section .event-list-item {
    background-color: #c9c9c9;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    color: #323434;
    transition: 0.1s linear all;
    display: flex;
    flex-direction: row;
    box-shadow: 4px 3px 8px 3px rgba(50, 50, 50, 0.25);
    margin-bottom: 3rem; }
    .events-list-section .event-list-item:hover {
      color: #3f4141; }
    .events-list-section .event-list-item .event-preview-graphic-wrapper {
      margin-right: 3rem; }
      .events-list-section .event-list-item .event-preview-graphic-wrapper img {
        width: 8rem;
        height: 8rem;
        border-radius: 4rem;
        border: 0.125rem solid white; }
    .events-list-section .event-list-item .event-action-button-wrapper + .event-action-button-wrapper {
      margin-left: 1rem; }
    .events-list-section .event-list-item .event-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-start; }
    .events-list-section .event-list-item .event-info-wrapper {
      flex: 1; }
      .events-list-section .event-list-item .event-info-wrapper .event-heading {
        display: flex;
        flex-direction: row;
        align-items: flex-start; }
        .events-list-section .event-list-item .event-info-wrapper .event-heading h2 {
          font-size: 2rem;
          margin-top: 0;
          margin-bottom: 0.75rem;
          font-weight: 300;
          flex: 1; }
        .events-list-section .event-list-item .event-info-wrapper .event-heading .event-actions {
          margin-bottom: 1rem; }
      .events-list-section .event-list-item .event-info-wrapper .event-date-location {
        font-size: 1.25rem;
        margin-bottom: 1.5rem; }
      .events-list-section .event-list-item .event-info-wrapper .event-assign-tickets {
        float: right; }
      .events-list-section .event-list-item .event-info-wrapper .event-description {
        color: #323434;
        line-height: 1.3;
        padding-right: 6rem;
        margin-top: 1rem; }
  @media (max-width: 740px) {
    .events-list-section .event-list-item {
      display: block;
      text-align: center; }
      .events-list-section .event-list-item .event-preview-graphic-wrapper {
        margin-right: 0; }
      .events-list-section .event-list-item .event-info-wrapper {
        text-align: center; }
        .events-list-section .event-list-item .event-info-wrapper .event-heading {
          display: block; }
        .events-list-section .event-list-item .event-info-wrapper .event-actions {
          display: block; }
          .events-list-section .event-list-item .event-info-wrapper .event-actions .event-action-button-wrapper {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 1rem; }
            .events-list-section .event-list-item .event-info-wrapper .event-actions .event-action-button-wrapper:first-child {
              margin-right: 0; }
            .events-list-section .event-list-item .event-info-wrapper .event-actions .event-action-button-wrapper .event-action-button {
              margin: auto; }
        .events-list-section .event-list-item .event-info-wrapper .event-assign-tickets {
          float: inherit; } }
  .events-list-section .event-action-button {
    background-color: #e3e3e3;
    padding: 0.75rem;
    border-radius: 0.5rem;
    min-width: 10rem;
    text-align: center;
    color: #323434; }
    .events-list-section .event-action-button:hover {
      background-color: #dedede;
      color: #3f4141; }
  @media (max-width: 640px) {
    .events-list-section .event-action-button {
      margin: 0 auto; } }

.form-buttons-wrapper {
  display: flex; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 200;
  min-height: 100vh; }

@media (max-width: 640px) {
  html,
  body {
    font-size: 14px; } }

#root {
  min-height: 100vh; }

p {
  font-weight: 200;
  font-size: 1.1rem;
  line-height: 1.2; }

a {
  text-decoration: none; }

.container {
  width: calc(100% - 16px);
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 32px;
  padding-bottom: 32px; }

.page-padding-bottom {
  padding-bottom: 5rem; }

.page-padding-top {
  padding-top: 2.5rem; }

.capitalize {
  text-transform: capitalize; }

.valign-middle {
  vertical-align: middle; }

.clickable {
  cursor: pointer; }
